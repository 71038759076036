// Gatsby supports TypeScript natively!
import React, {useEffect} from 'react';
import {PageProps} from 'gatsby';

import SEO from '../components/seo';
import Cal, {getCalApi} from '@calcom/embed-react';
// import queryString from 'query-string';

const ChatPage = (props: PageProps) => {
  const calLink = `joediv/chat`;

  useEffect(() => {
    (async function () {
      const cal = await getCalApi();
      // @ts-ignore
      cal('ui', {styles: {branding: {brandColor: '#436F70'}}, hideEventTypeDetails: false});
    })();
  }, []);

  return (
    <div className="min-h-screen w-screen sm:pt-20" style={{backgroundColor: '#E5E7EB'}}>
      <SEO title="Chat" />
      <Cal calLink={calLink} className="flex-auto hidden sm:block sm:overflow-auto" />
      <iframe src={`https://cal.com/${calLink}`} className="inline sm:hidden min-h-screen w-screen" />
      {/* <iframe src={`https://cal.com/${calLink}`} className="min-h-screen w-screen" /> */}
    </div>
  );
};

export default ChatPage;
